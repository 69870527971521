import * as React from "react"

export default () => {
  const [name, setName] = React.useState("")
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  if (isSubmitted) {
    return <div>
      <h1>Page sent</h1>
      <p>I'll get back to you as soon as I can.</p>
    </div>
  }
  return <div >
    <h1>'Page' Richard</h1>
    <p>I use 'Do not disturb' to make sure I stay focused.</p>
    <p>If you need me urgently this will send a loud notification to my phone and I will get back to you.</p>
    <form style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}} onSubmit={async (e) => {
      e.preventDefault()
      await fetch('https://ntfy.sh/rgillTA2aFAw6OX3YZQyo', {
        method: 'POST',
        headers: {
          'Title': `Page from: ${name} `,
          'Priority': 'urgent',
          'Tags': 'warning'
        }
      })
      setIsSubmitted(true)
    }}>
      <label htmlFor="name">Your Name</label>
      <input id="name" style={{marginBottom: 8, marginTop: 8}} onChange={(e) => setName(e.target.value)}/>
      <button type="submit">Send page to Richard</button>
    </form>
  </div>
}
